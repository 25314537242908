<template>
  <div class="assignment-page">
    <navbar/>
    <assignment-map
      @dragstart="onDragStart"
      @dragend="onDragEnd"/>
    <assignment-left-panel
      :class="{dragging: isDragging}"
      @show-pickupp-form="showPickuppForm"/>
    <assignment-right-panel
      :class="{dragging: isDragging}"
      @show-pickupp-form="showPickuppForm"/>
    <div
      v-if="highlightedTask"
      class="highlighted-task-info">
      <span class="highlighted-task-id">
        {{ highlightedTask.orderIdentifier }}
      </span>
      <span class="highlighted-task-address">
        {{ highlightedTask.deliveryAddress || highlightedTask.pickupPointName }}
      </span>
    </div>
    <b-modal
      :active.sync="pickuppFormModalActive"
      :width="320"
      animation
      class="pickupp-form-modal">
      <pickupp-form
        :task="pickuppFormTask"
        @confirm="confirmPickuppForm"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isTripDispatched } from '@js/trip-utils'
import pageAuthorizationMixin from '@/mixins/page-authorization-mixin'

const AssignmentLeftPanel = () => import('@components/AssignmentLeftPanel')
const AssignmentMap = () => import('@components/AssignmentMap')
const AssignmentRightPanel = () => import('@components/AssignmentRightPanel')
const Navbar = () => import('@components/Navbar')
const PickuppForm = () => import('@components/PickuppForm')

export default {
  name: 'assignment-page',
  components: {
    AssignmentLeftPanel,
    AssignmentMap,
    AssignmentRightPanel,
    Navbar,
    PickuppForm
  },
  mixins: [pageAuthorizationMixin],
  data() {
    return {
      isDragging: false,
      // show loading overlay for first load, but hide for subsequent loads initiated by actioncable
      isInitialIncomingTasksLoaded: false,
      isInitialTripsLoaded: false,
      pickuppFormTask: null,
      pickuppFormModalActive: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading',
      'getErrorMessage'
    ]),
    ...mapGetters('task', [
      'highlightedTask'
    ]),
    ...mapGetters('trip', [
      'getTrip'
    ]),
    loadingOverlayVisible() {
      return this.getLoading(
        'courier/getSortedCouriers',
        'task/assignTaskToPickupp',
        'task/getIncomingTasks',
        'task/reassignTaskToPickupp',
        'trip/assignTrips',
        'trip/reassignTrip',
        'trip/updateTrips'
      ) ||
      (!this.isInitialIncomingTasksLoaded && this.incomingTasksLoading) ||
      (!this.isInitialTripsLoaded && this.tripsLoading)
    },
    incomingTasksLoading() {
      return this.getLoading('task/getIncomingTasks')
    },
    tripsLoading() {
      return this.getLoading('trip/getTrips')
    },
    assignToPickuppError() {
      return this.getErrorMessage('task/assignTaskToPickupp') || this.getErrorMessage('task/reassignTaskToPickupp')
    }
  },
  created() {
    this.watchLoadingOnce('incomingTasksLoading', 'isInitialIncomingTasksLoaded')
    this.watchLoadingOnce('tripsLoading', 'isInitialTripsLoaded')

    this.watchErrorNotification('assignToPickuppError')
  },
  methods: {
    ...mapActions('task', [
      'assignTaskToPickuppRequest',
      'reassignTaskToPickuppRequest'
    ]),
    onDragStart() {
      this.isDragging = true
    },
    onDragEnd() {
      this.isDragging = false
    },
    showPickuppForm(task) {
      // skip if task is assigned to a dispatched trip
      const taskTrip = this.getTrip(task.tripId)
      if (taskTrip && isTripDispatched(taskTrip)) {
        return this.$buefy.dialog.alert({
          message: this.$t('assignment.rightPanel.assign.error.tripStarted')
        })
      }

      this.pickuppFormTask = task
      this.pickuppFormModalActive = true
    },
    confirmPickuppForm() {
      const isReassign = !!this.pickuppFormTask.tripId
      const callback = isReassign ? this.onReassignToPickuppComplete : this.onAssignToPickuppComplete
      const event = isReassign ? 'task/reassignTaskToPickuppSuccess' : 'task/assignTaskToPickuppSuccess'
      const request = isReassign ? this.reassignTaskToPickuppRequest : this.assignTaskToPickuppRequest

      this.$eventBus.$off(event, callback)
      this.$eventBus.$on(event, callback)
      request(this.pickuppFormTask.id)
    },
    onAssignToPickuppComplete(isReassign = false) {
      const callback = isReassign ? this.onReassignToPickuppComplete : this.onAssignToPickuppComplete
      const event = isReassign ? 'task/reassignTaskToPickuppSuccess' : 'task/assignTaskToPickuppSuccess'
      this.$eventBus.$off(event, callback)

      this.pickuppFormModalActive = false
      this.openToast({
        message: this.$t(`assignment.rightPanel.assign.${isReassign ? 'reassignSuccessMessage' : 'successMessage'}`),
        type: 'is-success'
      })
    },
    onReassignToPickuppComplete() {
      this.onAssignToPickuppComplete(true)
    }
  }
}
</script>

<style lang="scss" scoped>
$navbar-height: 44px;
$left-panel-width: 266px;
$right-panel-width: 300px;

.assignment-page {
  position: relative;
  height: 100vh;
  overflow: hidden;

  > .navbar {
    position: relative;
  }

  .assignment-map {
    top: $navbar-height;
    height: calc(100vh - #{$navbar-height});
  }

  .assignment-left-panel,
  .assignment-right-panel {
    position: absolute;
    top: $navbar-height;
    overflow-y: auto;
    color: $white;
    background-color: $grey-darker;
    transition: all 0.2s;

    &.dragging {
      opacity: 0.5;
    }
  }

  .assignment-left-panel {
    left: 0;
    width: $left-panel-width;
    height: calc(100vh - #{$navbar-height});

    &.dragging {
      left: -100px;
    }
  }

  .assignment-right-panel {
    right: 0;
    width: $right-panel-width;
    max-height: calc(100vh - #{$navbar-height});

    &.dragging {
      right: -100px;
    }
  }

  .highlighted-task-info {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: $space-m;
    pointer-events: none;
    background-color: $grey-lighter;
    @extend %body;

    > * {
      display: inline-block;

      &:not(:last-child) {
        padding-right: $space-xs;
      }
    }

    .highlighted-task-id {
      font-weight: bold;
    }
  }

  .pickupp-form-modal {
    &::v-deep {
      .modal-background {
        background-color: rgba(10, 10, 10, 0.5);
      }
    }
  }
}
</style>
